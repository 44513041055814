<template>
  <div>
    
  <v-data-table
    :headers="headers"
    :items="currencies"
    sort-by="cur_desc"
    class="elevation-1"
    dense
    :search="search"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Currency File Maintenance</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
        v-model="search"
        append-icon="search"
        label="Search"
        single-line
        hide-details
      ></v-text-field> 
		<vue-excel-xlsx
			:data="currencies"
			:columns="columnHeaders"
			:file-name="module_name"
			>
			<v-btn icon alt class="mt-2" :color="currencies.length=== 0 ? 'gray' : 'primary'" :disabled="currencies.length===0"><v-icon>mdi-arrow-down-bold-circle</v-icon> </v-btn>
		</vue-excel-xlsx>
      </v-toolbar>
    </template>
    <template v-slot:item.action="{ item }">
					<v-icon
					color="grey"
					class="mr-2"
					small
					v-if="role_access < 3"
				>
					mdi-pencil-off
				</v-icon>
				<v-icon
					color="primary"
					v-else
					class="mr-2"
					small
					@click="editItem(item)"
				>
					edit
				</v-icon>
					<v-icon
					color="grey"
					class="mr-2"
					small
					v-if="role_access < 4"
				>
					mdi-delete-off
				</v-icon>
				<v-icon
					small
					v-else
					color="red"
					@click="deleteItem(item)"
				>
					delete
				</v-icon>
			</template>
  </v-data-table>
  <create-currency v-if="role_access > 1"></create-currency>
  </div>
</template>

<script>
import createCurrency from './createCurrency.vue';
import { mapGetters } from 'vuex';

export default {
	components: {
		'create-currency': createCurrency
	},
	data(){
		return{
			module_name: 'Currencies',
			role_access: 1,
			search: ''
		};
	},
	computed: {
		...mapGetters({
			currencies: 'currency/currencies',
			headers: 'currency/headers',
			columnHeaders: 'currency/columnHeaders',
			editedItem: 'currency/editedItem',
			defaultItem: 'currency/defaultItem',
			dialog: 'currency/dialog',
			editedIndex: 'currency/editedIndex',
			valid: 'currency/valid',
			currUser: 'auth/currUser'
		}),
	},
	mounted () {
		this.$store.dispatch('currency/getCurrencies');

		let roles = this.currUser.roleaccess;
		if(roles.length > 0){
			let roleaccess = roles.filter(r => r.module_name === this.module_name);
			if(roleaccess.length > 0){
				this.role_access = roleaccess[0].access_level;
			}
		}
	},
	methods: {
		editItem (item) {
			this.$store.dispatch('currency/setEditedIndex',this.currencies.indexOf(item));
			this.$store.dispatch('currency/setEditedItems',Object.assign({}, item));
			this.$store.dispatch('currency/setDialog',true);
			this.$store.dispatch('currency/setValid',true);
			this.$store.dispatch('currency/setformTitle','Edit Entry');
		},
		deleteItem (item) {
			this.$swal.fire({
				title: 'Delete ' + item.cur_code + '(' + item.cur_desc + ')'  + '?',
				text: 'You won\'t be able to revert this!',
				type: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!'
			}).then((result) => {
				if (result.value) {
					this.$store.dispatch('currency/setisLoading',true);
					this.$store.dispatch('currency/deleteCurrency',item);
				}
			});
		}
	}
};
</script>