<template>
	<div class="home">
		<table-currency></table-currency>
  </div>
</template>

<script>
import tableCurrency from '@/components/pages/maint/currency/tableCurrency.vue';

export default {
	components: {
		'table-currency': tableCurrency
	}
};
</script>

<style>

</style>
